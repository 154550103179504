import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { CookiesAccept } from '../components/containers/cookies-accept/cookies-accept';
import { GlobalLoader } from '../components/containers/global-loader/global-loader';
import { SearchGeolocationModal } from '../components/containers/search-geolocation-modal/search-geolocation-modal';
import { SeoForHead } from '../components/containers/seo-for-head/seo-for-head';
import { SnackbarsStack } from '../components/containers/snackbars-stack/snackbars-stack';
import RootLayout from '../components/layout/root-layout';
import Footer from '../components/sections/footer/footer';
import Header from '../components/sections/header/header';
import Shell from '../components/shell.component';
import '@support-editor/mts-editor-dist/dist/style.css';
import { UserAgentContext } from '../context/user-agent-context';
import { useClearSiteData } from '../custom-hooks/use-clear-site-data';
import { fixScrollIntoAnchor } from '../helpers/scroll-into-anchor';
import { userAgent } from '../helpers/user-agent';
import { title, contentSupport } from '../meta-tags';
import { nextWrapper } from '../redux/store';
import { defaultTheme } from '../styles/themes/default';
import { GlobalStyle } from '../styles/themes/styled-global';
import { useInitialProps } from '../custom-hooks/use-initial-props.hook';
import { PagePropsModel } from '../models/page-props.model';

fixScrollIntoAnchor();

const Application = ({ Component, ...rest }: AppProps) => {
    useInitialProps((rest?.pageProps as PagePropsModel)?.env);

    const { store, props } = nextWrapper.useWrappedStore(rest);

    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log(`support app version: %c${process.env.APP_VERSION}`, 'color: #263238; font-weight: bold;');
    }, [store]);

    useClearSiteData();

    return (
        <Provider store={store}>
            <UserAgentContext.Provider value={userAgent}>
                <SnackbarsStack/>
                <CookiesAccept/>
                <SeoForHead title={title} descriptionContent={contentSupport}/>

                <GlobalStyle theme={defaultTheme}/>
                <ThemeProvider theme={defaultTheme}>
                    <Shell>
                        <RootLayout>
                            <GlobalLoader />
                            <SearchGeolocationModal />
                            <Header />
                            <Component {...props.pageProps} />
                            <Footer />
                        </RootLayout>
                    </Shell>
                </ThemeProvider>
            </UserAgentContext.Provider>
        </Provider>
    );
};

export default Application;
