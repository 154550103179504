export const color = {
    brandLightMtsRed: '#FF0032',

    controlsLightPrimaryActive: '#FF0032',
    controlsLightTertiaryActive: '#F2F3F7',

    backgroundLightPrimaryElevated: '#FFFFFF',
    backgroundLightSecondary: '#F2F3F7',

    iconsLightPrimary: '#000000',
    iconsLightSecondary: '#969FA8',
    iconsLightTertiary: '#BBC1C7',

    textLight: '#FFFFFF',
    textLightSecondary: '#626C77',
    textLightTertiary: '#969FA8',
    textLightHeadline: '#000000',
    textLightPrimary: '#1D2023',

    textLightPrimaryLink: '#0070E5',
    textLightSecondaryLink: '#969FA8',

    accentLightPositive: '#26CD58',
    accentLightActive: '#0070E5',

    primaryBorderColor: '#E2E5EB',

    sidebarBgActive: '#EFF0F3',

    buttonSecondaryBg: '#FFFFFF',
    buttonSecondaryHoverBg: '#F2F3F7',
    buttonDisabledBg: 'rgba(188, 195, 208, 0.5)',
    buttonDisabled: '#969FA8',

    iconButtonBg: '#F2F3F7',

    loaderPrimary: '#FF0032',
    loaderDark: '#000000',
    loaderLight: '#FFFFFF',
    loaderError: '#F95721',
    loaderBackground: 'rgba(255, 255, 255, 0.5)',

    badgeColor: '#FF0032',

    overlayBg: 'rgba(29, 32, 35, 0.4)',

    checkboxBorder: '#8D969F',
    searchInputBg: '#F2F3F7',
    checkboxDisabledBg: 'rgba(188, 195, 208, 0.5)',
    checkboxDisabledMark: 'rgba(141, 150, 159)',
};
