import { FC } from 'react';
import styled from 'styled-components';
import { rem } from '../../helpers/rem-converter';
import {
    desktopS,
    desktopXS,
    mobile,
    tablet,
} from '../../styles/themes/media';

export const sidePaddingsDesktop = 56;
export const sidePaddingsDesktopS = 48;
export const sidePaddingsDesktopXS = 40;
export const sidePaddingsTablet = 40;
export const sidePaddingsMobile = 20;

export const contextWidth = 1168;

export const StyledOuterContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 ${rem(sidePaddingsDesktop)};

    ${desktopS`padding: 0 ${rem(sidePaddingsDesktopS)}`};
    ${desktopXS`padding: 0 ${rem(sidePaddingsDesktopXS)}`};
    ${tablet`padding: 0 ${rem(sidePaddingsTablet)}`};
    ${mobile`padding: 0 ${rem(sidePaddingsMobile)}`};
`;

export const StyledInnerContainer = styled.div`
    width: 100%;
    max-width: ${rem(1168)};
`;

const Container: FC<{ children: React.ReactNode; className?: string }> = ({ children, className }) => (
    <StyledOuterContainer className={className}>
        <StyledInnerContainer>{children}</StyledInnerContainer>
    </StyledOuterContainer>
);

export default Container;
