import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { border, borderRadius } from '../../../styles/tokens/border';
import { color } from '../../../styles/tokens/color';
import { fontSize } from '../../../styles/tokens/font-size';

export const Textarea = styled.textarea<{ inputCss?: FlattenSimpleInterpolation }>`
    width: 100%;
    box-sizing: border-box;
    padding: 0 ${rem(10)};
    padding-top: ${rem(11)};
    padding-bottom: ${rem(9)};
    border: ${border.bold};
    border-radius: ${borderRadius.radius12};
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    outline: none;
    background-color: ${color.backgroundLightPrimaryElevated};

    &:focus {
        border: 2px solid ${color.accentLightActive};
    }

    ${({ inputCss }) => inputCss};
`;

export const Textbox = styled(Textarea)`
    background-color: ${color.backgroundLightPrimaryElevated};
    && {
        max-height: ${rem(44)};
        padding-left: ${rem(40)};
        ${(p) =>
        p.value &&
            css`
                padding-right: ${rem(45)};
            `};
        font-size: ${fontSize.standard};
        line-height: ${rem(24)};
    }

    &::placeholder {
        color: ${color.textLightSecondary};
    }

    ${({ inputCss }) => inputCss};
`;

export const BeforeIcon = styled.div`
    left: ${rem(15)};
    position: absolute;
    display: flex;
    align-items: center;
`;

export const TextboxContainer = styled.label`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
`;
export const CrossWrapper = styled.div`
    display: flex;
    position: absolute;
    right: ${rem(18)};
    cursor: pointer;
`;
